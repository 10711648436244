<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('welcomeTo') }} {{ companyName }}! 👋🏻
                  </p>
                  <p v-t="'pleaseSingInToYourAccount'" class="mb-2" />
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      :label="$t('email', { postfix: '' })"
                      :placeholder="$t('email', { postfix: '' })"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                      maxlength="254"
                      autocomplete="username"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :label="$t('password')"
                      :error-messages="errorMessages.password"
                      :placeholder="$t('password')"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      maxlength="128"
                      autocomplete="current-password"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-end flex-wrap">
                      <!-- forget link -->
                      <router-link :to="{ name: 'auth-forgot-password' }" class="ms-3">
                        {{ $t('forgotPassword') }}
                      </router-link>
                    </div>

                    <v-btn block color="primary" type="submit" class="mt-6" :loading="loading">
                      {{ $t('login') }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p v-t="'newOnOurPlatform'" class="mb-0 me-2" />
                  <router-link :to="{ name: 'auth-register' }"> {{ $t('createAnAccount') }} </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import { useActions, useState } from 'vuex-composition-helpers'
import { AbilityBuilder, Ability } from '@casl/ability'
import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import defineRules from '@/plugins/acl/config'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const loading = ref(false)
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const companyName = null

    const { loginWithEmailAPI, getRecruiterProfile } = useActions('recruiter', [
      'loginWithEmailAPI',
      'getRecruiterProfile',
    ])
    const { recruiter } = useState('recruiter', ['recruiter'])

    const { getSettings } = useActions('company', ['getSettings'])
    const { businessSettings } = useState('company', ['businessSettings'])

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      loading.value = true
      const loggedIn = await loginWithEmailAPI({ email: email.value, password: password.value })

      const user = {
        id: 1,
        fullName: 'John Doe',
        username: 'johndoe',
        password: 'admin',
        // eslint-disable-next-line global-require
        avatar: require('@/assets/images/avatars/1.png'),
        email: 'admin@materio.com',
        role: 'admin',
        ability: [
          {
            action: 'manage',
            subject: 'all',
          },
        ],
      }

      // localStorage.setItem('userData', JSON.stringify(user))

      // On success redirect to home
      loading.value = false
      if (loggedIn) {
        await getRecruiterProfile()
        await getSettings()

        const { rules } = defineRules(recruiter.value.access, recruiter.value.id, businessSettings.value)
        vm.$ability.update(rules)
        localStorage.setItem('userAbility', JSON.stringify(rules))

        await router.push('/')
      } else {
        loginForm.value.reset()
      }
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      loading,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      companyName,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
  async created() {
    const { data } = await this.$http.get('company/current/name/')
    this.companyName = data.name
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
